import { Modal, Button, AlertBanner } from '@cae/cobalt-react';
import { useState } from 'react';
import { useCancelRequestQuery } from '@/features/instructor-management/api/useCancelRequestQuery.ts';
import { LoaderCircularComponent } from '@/shared/components';
import './CancelModal.css';

interface CancelModalProps {
  readonly isOpen: boolean;
  readonly onClose: (result: 'success' | 'failure') => void;
  readonly requestId: string;
}

const CancelModal = ({
  isOpen,
  onClose,
  requestId,
}: Readonly<CancelModalProps>): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { cancelRequest } = useCancelRequestQuery();

  const handleCancelRequest = async (): Promise<void> => {
    setIsLoading(true);
    setError(null);
    await cancelRequest(requestId)
      .then(() => {
        setIsLoading(false);
        onClose('success');
      })
      .catch(() => {
        setIsLoading(false);
        setError('Failed to cancel the request. Please try again.');
      });
  };

  const handleCancel = (): void => {
    setError(null);
    onClose('failure');
  };

  return (
    <Modal
      open={isOpen}
      onCancel={() => onClose('failure')}
      dismissible
      size="sm"
      className={'cancel-modal'}
    >
      <div className="modal-content">
        <h2>Cancel Request</h2>
        {isLoading && <LoaderCircularComponent key={'cancel request'} />}
        {error && <AlertBanner type="error" heading={error} />}
        <p>Are you sure you want to cancel this request?</p>
        <div className="modal-actions">
          <Button
            variant="secondary"
            onClick={handleCancel}
            disabled={isLoading}
          >
            Close
          </Button>
          <Button
            variant="primary"
            disabled={isLoading}
            onClick={handleCancelRequest}
          >
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CancelModal;
