import { Icon } from '@cae/cobalt-react';

import type { ReservationData } from '@/features/reservation-details/types';
import { useHasPermission } from '@/shared/hooks/useHasPermission';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNavigate } from 'react-router-dom';

const etcaIconMapping: { [k: string]: string } = {
  success: 'checkmark-circle-solid',
  warning: 'warning-triangle-solid',
  danger: 'warning-triangle-solid',
  neutral: 'circle-outline',
  'neutral-pending': 'circle-dotted-outline',
};

function getTcaAppUrl(geminiId: string, authType = 'b2c'): string {
  return encodeURI(
    `${import.meta.env.VITE_TCA_APP_URL}${geminiId}?auth_type=${authType}`
  );
}

export function ETCAStatusActions({
  data,
  reservationId,
}: {
  data: ReservationData['statusInfo']['etca'];
  reservationId: string;
}): JSX.Element {
  const hasPermission = useHasPermission();
  const { eTcaEnabledWebApp } = useFlags();
  const navigate = useNavigate();

  const navigateToEtca = (): void => {
    navigate(`/eTCA`, { state: { reservationId } });
  };

  return (
    <article>
      <h4>eTCA status</h4>
      {data && (
        <p>
          {data.style && (
            <Icon
              id={etcaIconMapping[data.style]}
              className={`status ${data.style}`}
              size="sm"
            />
          )}
          {data.message}
        </p>
      )}
      {hasPermission('reservations_tca_actions') ? (
        <div className="actions">
          {eTcaEnabledWebApp ? (
            <button onClick={navigateToEtca}>
              Submit now <Icon id="external-link-solid" size="sm" />
            </button>
          ) : (
            <a
              href={getTcaAppUrl(reservationId)}
              rel="noreferrer"
              target="_blank"
            >
              {data.style !== 'success' ? 'Submit now' : 'View'}{' '}
              <Icon id="external-link-solid" size="sm" />
            </a>
          )}
        </div>
      ) : null}
    </article>
  );
}
