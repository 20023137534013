import { useInstructorDetailsQuery } from '@/features/instructor-management/api/useInstructorDetailsQuery';
import {
  getStatusIcon,
  statusText,
} from '@/features/instructor-management/shared/Utils.tsx';
import { Modal, Button, Icon, AlertBanner } from '@cae/cobalt-react';
import {
  ActionModal,
  ActionOverflowMenu,
} from '@/features/instructor-management';
import { InstructorApprovalState } from '@/open-api';
import './InstructorDetails.css';
import { DataLoadingError, DataLoadingGrid, NoData } from '@/shared/components';
import { useCallback, useState } from 'react';
import ApprovalLetterUploadModal from '@/features/instructor-management/components/approval-letter-upload-modal/ApprovalLetterUploadModal.tsx';
import CancelModal from '@/features/instructor-management/components/cancel-modal/CancelModal.tsx';

interface InstructorDetailsProps {
  instructorId: string;
  instructorName: string;
  programName: string;
  trainingCenterName: string;
  instructorDetailsOpen: boolean;
  toggleInstructorDetails: () => void;
  onBtnClick: (action: string) => void;
}

interface SelectedInstructorDetails {
  program?: string;
  trainingCenterId?: string;
  trainingCenterName?: string;
  groundSchoolApprovalState?: InstructorApprovalState;
  simulatorApprovalState?: InstructorApprovalState;
  checkingApprovalState?: InstructorApprovalState;
}

const InstructorDetails = ({
  instructorId,
  instructorName,
  programName,
  trainingCenterName,
  instructorDetailsOpen,
  toggleInstructorDetails,
  onBtnClick,
}: Readonly<InstructorDetailsProps>): JSX.Element => {
  const {
    data: instructorDetails,
    isLoading,
    isSuccess,
    refetch,
  } = useInstructorDetailsQuery(instructorId);
  const approvalLetterRequestId =
    instructorDetails?.statusesByProgramAndTrainingCenter?.find(
      item =>
        item.trainingCenterName === trainingCenterName &&
        item.program === programName
    )?.checkingApproval?.requestId;
  const mainProgram =
    instructorDetails?.statusesByProgramAndTrainingCenter?.find(
      item =>
        item.trainingCenterName === trainingCenterName &&
        item.program === programName
    );
  const [modalOpen, setModalOpen] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isCancelSuccess, setIsCancelSuccess] = useState(false);
  const [action, setAction] = useState('');
  const [selectedLocation, setSelectedLocation] =
    useState<SelectedInstructorDetails>({});
  const [requestId, setRequestId] = useState('');

  const formKey = `${Date.now()}`;

  const toggleUploadModal = useCallback((): void => {
    setIsUploadModalOpen(!isUploadModalOpen);
  }, [isUploadModalOpen]);

  const toggleCancelModal = useCallback(
    (status?: string): void => {
      if (status === 'success') {
        setIsCancelSuccess(true);
        refetch();
      }
      setIsCancelModalOpen(!isCancelModalOpen);
    },
    [isCancelModalOpen, refetch]
  );

  const handleMenuBtnClick = (
    action: string,
    location: SelectedInstructorDetails
  ): void => {
    setAction(action);
    setSelectedLocation(location);
    setModalOpen(true);
    toggleInstructorDetails();
  };

  const handleCancel = useCallback(
    ({ requestId }: { requestId: string }): void => {
      setRequestId(requestId);
      toggleCancelModal();
    },
    [toggleCancelModal]
  );

  const renderDisciplineSection = useCallback(
    (discipline: string, approvalState: string): JSX.Element => {
      let actionButton;
      const actionHandler = (action: string): void => {
        onBtnClick(action);
        toggleInstructorDetails();
      };

      switch (approvalState) {
        case InstructorApprovalState.APPROVED:
          actionButton = (
            <Button
              variant="ghost"
              onClick={() => actionHandler('revoke')}
              className="action-button"
              danger
            >
              <Icon id={'close-circle-outline'} /> Revoke
            </Button>
          );
          break;
        case InstructorApprovalState.UNSELECTED:
          actionButton = (
            <Button
              variant="ghost"
              onClick={() => actionHandler('approve')}
              className="action-button"
            >
              <Icon id={'checkmark-circle-outline'} /> Approve
            </Button>
          );
          break;
        case InstructorApprovalState.PENDING:
        case InstructorApprovalState.PENDING_REMOVAL: {
          let requestId: string;
          if (discipline === 'Ground School') {
            requestId = mainProgram?.groundSchoolApproval?.requestId ?? '';
          } else if (discipline === 'Simulator Training') {
            requestId = mainProgram?.simulatorApproval?.requestId ?? '';
          } else {
            requestId = mainProgram?.checkingApproval?.requestId ?? '';
          }
          actionButton = requestId && !isCancelSuccess && (
            <Button
              variant="ghost"
              onClick={() => handleCancel({ requestId: requestId })}
              className="action-button"
            >
              Cancel Request
            </Button>
          );
          break;
        }
        case 'Request Cancelled':
          actionButton = <></>;
          break;
        case InstructorApprovalState.PENDING_APPROVAL_LETTER:
          actionButton = approvalLetterRequestId && (
            <Button
              variant="ghost"
              onClick={toggleUploadModal}
              className="action-button"
            >
              <Icon id={'upload-outline'} /> Upload Letter
            </Button>
          );
          break;
        case InstructorApprovalState.UNAUTHORIZED:
          actionButton = <></>;
          break;
        default:
          actionButton = <></>;
      }

      return (
        <div className="discipline-section">
          <div className="discipline-left">
            <span className="discipline-name">{discipline}</span>
            <div className="icon-text-alignment">
              <span>{getStatusIcon(approvalState)}</span>
              <span className="text">
                {statusText[approvalState as InstructorApprovalState]}
              </span>
            </div>
          </div>
          <div className="discipline-right">{actionButton}</div>
        </div>
      );
    },
    [
      onBtnClick,
      toggleInstructorDetails,
      mainProgram?.groundSchoolApproval?.requestId,
      mainProgram?.simulatorApproval?.requestId,
      mainProgram?.checkingApproval?.requestId,
      isCancelSuccess,
      approvalLetterRequestId,
      toggleUploadModal,
      handleCancel,
    ]
  );

  const getAvailableFor = (): JSX.Element => {
    if (isLoading) {
      return <DataLoadingGrid numberColumns={3} numberRows={3} />;
    }
    if (
      isSuccess &&
      !instructorDetails?.statusesByProgramAndTrainingCenter?.length
    ) {
      return <NoData />;
    }
    if (
      isSuccess &&
      !!instructorDetails?.statusesByProgramAndTrainingCenter?.length
    ) {
      return (
        <>
          {instructorDetails?.alerts?.map(alert => (
            <AlertBanner
              key={alert.message}
              className="co-alert--warning"
              size="sm"
              type="warning"
              heading={alert.message}
            ></AlertBanner>
          ))}
          {instructorDetails?.statusesByProgramAndTrainingCenter?.map(
            program => (
              <div key={program.program} className="program-section">
                <h4 className="program-name">{program.program}</h4>
                <h5 className="training-center-name">
                  {program.trainingCenterName}
                </h5>
                <ul className="details-list">
                  <li className="icon-text-alignment">
                    <span>
                      {getStatusIcon(program.groundSchoolApproval?.state ?? '')}
                    </span>
                    <span className="text">Ground School</span>
                  </li>
                  <li className="icon-text-alignment">
                    <span>
                      {getStatusIcon(program.simulatorApproval?.state ?? '')}
                    </span>
                    <span className="text">Simulator training</span>
                  </li>
                  <li className="icon-text-alignment">
                    <span>
                      {getStatusIcon(program.checkingApproval?.state ?? '')}
                    </span>
                    <span className="text">Checking</span>
                  </li>
                  <li className="icon-text-alignment">
                    <ActionOverflowMenu
                      onBtnClick={handleMenuBtnClick}
                      instructor={{
                        ...program,
                        checkingApprovalState: program.checkingApproval?.state,
                        simulatorApprovalState:
                          program.simulatorApproval?.state,
                        groundSchoolApprovalState:
                          program.groundSchoolApproval?.state,
                      }}
                    />
                  </li>
                </ul>
              </div>
            )
          )}
        </>
      );
    } else {
      return <DataLoadingError />;
    }
  };
  return (
    <>
      <Modal
        open={instructorDetailsOpen}
        onCancel={toggleInstructorDetails}
        placement="right"
        dismissible
        size="md"
      >
        <div className="instructor-details">
          <div className="top-half">
            <h2 className="instructor-name">{instructorName}</h2>
            <h3 className="program-name">{programName}</h3>
            <h4 className="training-center-name">{trainingCenterName}</h4>
            {renderDisciplineSection(
              'Ground School',
              mainProgram?.groundSchoolApproval?.state ?? ''
            )}
            {renderDisciplineSection(
              'Simulator Training',
              mainProgram?.simulatorApproval?.state ?? ''
            )}
            {renderDisciplineSection(
              'Checking',
              mainProgram?.checkingApproval?.state ?? ''
            )}
          </div>
          <div className="bottom-half">
            <h3 className="available-for-title">Available for</h3>
            {getAvailableFor()}
          </div>
        </div>
      </Modal>
      <ActionModal
        modalOpen={modalOpen}
        toggleActionModal={() => setModalOpen(false)}
        action={action}
        selectedInstructor={selectedLocation}
        program={selectedLocation?.program ?? ''}
        location={selectedLocation?.trainingCenterName ?? ''}
        trainingCenterId={selectedLocation?.trainingCenterId ?? ''}
      />
      <ApprovalLetterUploadModal
        isUploadModalOpen={isUploadModalOpen}
        toggleUploadModal={toggleUploadModal}
        instructorName={instructorName}
        requestId={approvalLetterRequestId ?? ''}
        formKey={formKey}
      />
      <CancelModal
        isOpen={isCancelModalOpen}
        onClose={toggleCancelModal}
        requestId={requestId}
      />
    </>
  );
};

export default InstructorDetails;
